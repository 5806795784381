import { createApp, shallowRef } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import * as Sentry from '@sentry/vue';

const app = createApp(App);

// bootstrap
import * as bootstrap from 'bootstrap';

window.bootstrap = bootstrap;

// modals
import '@/assets/sass/components/custom-modal.scss';

// perfect scrollbar
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';

//vue-meta
import { createHead } from '@vueuse/head';

const head = createHead();

//Sweetalert
import Swal from 'sweetalert2';

window.Swal = Swal;

// nouislider - later remove and add to page due to not working in page
import VueNouislider from 'vue3-nouislider';
import 'vue3-nouislider/dist/vue3-nouislider.css';

// vue input mask
import { vMaska } from 'maska';

// smooth scroll
import { registerScrollSpy } from 'vue3-scroll-spy';

registerScrollSpy(app, { offset: 118 });

//vue-i18n
import i18n from './i18n';

// Feather icon
import VueFeather from 'vue-feather';

app.component(VueFeather.name, VueFeather);

// datatables
import { ServerTable, ClientTable } from 'v-tables-3';


// set default settings
import appSetting from './app-setting';

window.$appSetting = appSetting;
window.$appSetting.init();

import setupInterceptors from './services/setupInterceptors';

setupInterceptors(store);

// Pagination
import Pagination from 'v-pagination-3';

app.component('pagination', Pagination);

// import the package
import VueAwesomePaginate from 'vue-awesome-paginate';

// import the necessary css file
app.use(VueAwesomePaginate);


// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faEarthEurope,
    faChartPie,
    faBarsProgress,
    faShare,
    faHeart,
    faHeartbeat,
    faCompactDisc,
    faSmile,
    faTasks,
    faTint,
    faCopy,
    faHammer,
    faComments,
    faSms,
    faBug,
    faTruckFast,
    faMapMarker,
    faDoorOpen,
    faSort,
    faSortUp,
    faSortDown,
    faUserCog,
    faUserPlus,
    faUserMinus,
    faUserNurse,
    faStairs,
    faFax,
    faFire,
    faWater,
    faVideo,
    faThList,
    faTruckMoving,
    faBoxes,
    faKeyboard,
    faUnlockAlt,
    faLaptopMedical,
    faDoorClosed,
    faBox,
    faStore,
    faWheelchair,
    faCamera,
    faPercentage,
    faExchangeAlt,
    faBolt,
    faMoneyCheckAlt,
    faUserTimes,
    faHourglassHalf,
    faSignInAlt,
    faSimCard,
    faBalanceScale,
    faBold,
    faShapes,
    faBatteryFull,
    faUserClock,
    faEllipsisH,
    faTachometerAlt,
    faRoute,
    faPowerOff,
    faUserShield,
    faCloudUploadAlt,
    faMemory,
    faSortNumericDown,
    faShieldVirus,
    faStream,
    faUserCheck,
    faRedoAlt,
    faUserLock,
    faUserTag,
    faCouch,
    faIndustry,
    faCalendarWeek,
    faSortAmountUp,
    faTruck,
    faWallet,
    faUsersCog,
    faCodeBranch,
    faCheckDouble,
    faThermometerHalf,
    faHeading,
    faRulerCombined,
    faUserCircle,
    faQuestion,
    faBroadcastTower,
    faFingerprint,
    faInfinity,
    faForward,
    faArrowAltCircleDown,
    faArrowAltCircleUp,
    faFileContract,
    faAt,
    faCommentDots,
    faPhoneAlt,
    faThumbtack,
    faIdBadge,
    faEnvelopeOpenText,
    faEnvelopeCircleCheck,
    faInbox,
    faMailBulk,
    faHourglassEnd,
    faIdCardAlt,
    faClock,
    faCalendarPlus,
    faBrain,
    faInfo,
    faInfoCircle,
    faQrcode,
    faWifi3,
    faThumbsUp,
    faUserTie,
    faRobot,
    faSkullCrossbones,
    faMedal,
    faBell,
    faFilter,
    faTableColumns,
    faBars,
    faBan,
    faSyncAlt,
    faHardDrive,
    faVirus,
    faFileInvoice,
    faHand,
    faPlug,
    faEllipsisVertical,
    faCircleDot,
    faCircleCheck,
    faCircleExclamation,
    faClockRotateLeft,
    faDiagramProject,
    faMagicWandSparkles,
    faWandMagic,
    faAlignJustify,
    faChevronDown,
    faChevronUp,
    faAlignLeft,
    faAlignCenter,
    faAlignRight,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faArrowDown,
    faSliders,
    faImage,
    faWindowRestore,
    faPalette,
    faChartBar,
    faCode,
    faShieldAlt,
    faCubes,
    faMobileAlt,
    faCloud,
    faDesktop,
    faMoneyBillWave,
    faIdCard,
    faConciergeBell,
    faCogs,
    faCertificate,
    faBookmark,
    faLink,
    faMinus,
    faPlus,
    faArrowRightFromBracket,
    faTriangleExclamation,
    faTrash,
    faBook,
    faCircleQuestion,
    faBriefcase,
    faEarthAmericas,
    faAddressBook,
    faFloppyDisk,
    faFileCsv,
    faFileExcel,
    faFilePdf,
    faPrint,
    faPhone,
    faPencil,
    faNoteSticky,
    faComputer,
    faSquare,
    faSquarePlus,
    faSquareMinus,
    faCheck,
    faMicrochip,
    faMask,
    faMessage,
    faAward,
    faFileLines,
    faHandsHoldingCircle,
    faMoneyBill,
    faMaximize,
    faUpRightAndDownLeftFromCenter,
    faFile,
    faBoxArchive,
    faChevronRight,
    faEarthAmerica,
    faBuilding,
    faHashtag,
    faNetworkWired,
    faEthernet,
    faCircle,
    faLaptop,
    faCalculator,
    faLockOpen,
    faLock,
    faChair,
    faHandshake,
    faCalendar,
    faCalendarXmark,
    faCalendarDay,
    faCalendarDays,
    faCalendarCheck,
    faTag,
    faTags,
    faDollarSign,
    faReceipt,
    faFileInvoiceDollar,
    faArrowsRotate,
    faGlobe,
    faMobile,
    faEnvelope,
    faServer,
    faWeightScale,
    faStar,
    faStarHalf,
    faSpinner,
    faFolder,
    faFolderOpen,
    faFolderClosed,
    faKey,
    faWifi,
    faDatabase,
    faShield,
    faFileShield,
    faVault,
    faUserSecret,
    faFileCode,
    faLocationDot,
    faMagnifyingGlass,
    faXmark,
    faGear,
    faBarcode,
    faCreditCard,
    faClone,
    faPassport,
    faEye,
    faEyeSlash,
    faClipboardList,
    faScrewdriverWrench,
    faExternalLink,
    faUsers,
    faUser
} from '@fortawesome/free-solid-svg-icons';


import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
    faEarthEurope,
    faChartPie,
    faBarsProgress,
    faShare,
    faHeart,
    faHeartbeat,
    faCompactDisc,
    faSmile,
    faTasks,
    faTint,
    faCopy,
    faHammer,
    faComments,
    faSms,
    faBug,
    faTruckFast,
    faMapMarker,
    faDoorOpen,
    faSort,
    faSortUp,
    faSortDown,
    faUserCog,
    faUserPlus,
    faUserMinus,
    faUserNurse,
    faStairs,
    faFax,
    faFire,
    faWater,
    faVideo,
    faThList,
    faTruckMoving,
    faBoxes,
    faKeyboard,
    faUnlockAlt,
    faLaptopMedical,
    faDoorClosed,
    faBox,
    faStore,
    faWheelchair,
    faCamera,
    faPercentage,
    faExchangeAlt,
    faBolt,
    faMoneyCheckAlt,
    faUserTimes,
    faHourglassHalf,
    faSignInAlt,
    faSimCard,
    faBalanceScale,
    faBold,
    faShapes,
    faBatteryFull,
    faUserClock,
    faEllipsisH,
    faTachometerAlt,
    faRoute,
    faPowerOff,
    faUserShield,
    faCloudUploadAlt,
    faMemory,
    faSortNumericDown,
    faShieldVirus,
    faStream,
    faUserCheck,
    faRedoAlt,
    faUserLock,
    faUserTag,
    faCouch,
    faIndustry,
    faCalendarWeek,
    faSortAmountUp,
    faTruck,
    faWallet,
    faUsersCog,
    faCodeBranch,
    faCheckDouble,
    faThermometerHalf,
    faHeading,
    faRulerCombined,
    faUserCircle,
    faQuestion,
    faBroadcastTower,
    faFingerprint,
    faInfinity,
    faForward,
    faArrowAltCircleDown,
    faArrowAltCircleUp,
    faFileContract,
    faAt,
    faCommentDots,
    faPhoneAlt,
    faThumbtack,
    faIdBadge,
    faEnvelopeOpenText,
    faEnvelopeCircleCheck,
    faInbox,
    faMailBulk,
    faHourglassEnd,
    faIdCardAlt,
    faClock,
    faCalendarPlus,
    faBrain,
    faInfo,
    faInfoCircle,
    faQrcode,
    faWifi3,
    faThumbsUp,
    faUserTie,
    faRobot,
    faSkullCrossbones,
    faMedal,
    faBell,
    faFilter,
    faTableColumns,
    faBars,
    faBan,
    faSyncAlt,
    faHardDrive,
    faVirus,
    faFileInvoice,
    faHand,
    faPlug,
    faEllipsisVertical,
    faCircleDot,
    faCircleCheck,
    faCircleExclamation,
    faClockRotateLeft,
    faDiagramProject,
    faMagicWandSparkles,
    faWandMagic,
    faAlignJustify,
    faChevronDown,
    faChevronUp,
    faAlignLeft,
    faAlignCenter,
    faAlignRight,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faArrowDown,
    faSliders,
    faImage,
    faWindowRestore,
    faPalette,
    faChartBar,
    faCode,
    faShieldAlt,
    faCubes,
    faMobileAlt,
    faCloud,
    faDesktop,
    faMoneyBillWave,
    faIdCard,
    faConciergeBell,
    faCogs,
    faCertificate,
    faBookmark,
    faLink,
    faMinus,
    faPlus,
    faArrowRightFromBracket,
    faTriangleExclamation,
    faTrash,
    faBook,
    faCircleQuestion,
    faBriefcase,
    faEarthAmericas,
    faAddressBook,
    faFloppyDisk,
    faFileCsv,
    faFileExcel,
    faFilePdf,
    faPrint,
    faPhone,
    faCheck,
    faComputer,
    faNoteSticky,
    faSquarePlus,
    faSquareMinus,
    faPencil,
    faSquare,
    faMicrochip,
    faMask,
    faMessage,
    faAward,
    faFileLines,
    faHandsHoldingCircle,
    faMoneyBill,
    faMaximize,
    faUpRightAndDownLeftFromCenter,
    faFile,
    faBoxArchive,
    faChevronRight,
    faEarthAmerica,
    faBuilding,
    faHashtag,
    faNetworkWired,
    faEthernet,
    faCircle,
    faLaptop,
    faCalculator,
    faLockOpen,
    faLock,
    faChair,
    faHandshake,
    faCalendar,
    faCalendarXmark,
    faCalendarDay,
    faCalendarDays,
    faCalendarCheck,
    faTag,
    faTags,
    faDollarSign,
    faReceipt,
    faFileInvoiceDollar,
    faArrowsRotate,
    faGlobe,
    faMobile,
    faEnvelope,
    faServer,
    faWeightScale,
    faStar,
    faStarHalf,
    faSpinner,
    faFolder,
    faFolderOpen,
    faFolderClosed,
    faKey,
    faWifi,
    faDatabase,
    faShield,
    faFileShield,
    faVault,
    faUserSecret,
    faFileCode,
    faLocationDot,
    faMagnifyingGlass,
    faXmark,
    faGear,
    faBarcode,
    faCreditCard,
    faClone,
    faPassport,
    faEye,
    faEyeSlash,
    faClipboardList,
    faScrewdriverWrench,
    faExternalLink,
    faUsers,
    faUser
);
import {
    faGithub,
    faAppStoreIos,
    faApple,
    faAmazon,
    faWindows,
    faUbuntu,
    faLinux,
    faSuse,
    faRedhat,
    faCentos,
    faFedora,
    faGoogle,
    faDropbox,
    faUsps,
    faUps,
    faFedex,
    faDhl,
    faAws
} from '@fortawesome/free-brands-svg-icons';

library.add(
    faGithub,
    faApple,
    faAppStoreIos,
    faAmazon,
    faWindows,
    faUbuntu,
    faLinux,
    faSuse,
    faRedhat,
    faCentos,
    faFedora,
    faGoogle,
    faDropbox,
    faUsps,
    faUps,
    faFedex,
    faDhl,
    faAws);

import { faCircle as fasCircle } from '@fortawesome/free-regular-svg-icons';

library.add(fasCircle);

app.component('font-awesome-icon', FontAwesomeIcon);


// Custom Table for Folder View
import myGroup from './vue-table-customize/VtGroupRow';

const group_row = shallowRef(myGroup);
import myTableHeading from './vue-table-customize/VtTableHeading.vue';

const tableHeading = shallowRef(myTableHeading);

// Setup V Network Graph
import VNetworkGraph from 'v-network-graph';
import 'v-network-graph/lib/style.css';

//vue-wizard
import VueFormWizard from 'vue3-form-wizard';
import 'vue3-form-wizard/dist/style.css';

Sentry.init({
    app,
    dsn: 'https://0452cffebee156e433d1bde58cdf1417@o4506109602889728.ingest.sentry.io/4506109604134912',
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['localhost', /^https:\/\/it-folder\.com/],
            routingInstrumentation: Sentry.vueRouterInstrumentation(router)
        }),
        new Sentry.Replay()
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

import { install as VueMonacoEditorPlugin } from '@guolao/vue-monaco-editor';

app.use(store).use(setupInterceptors).use(router).use(i18n).use(PerfectScrollbar).use(VueNouislider).use(ClientTable, {}, 'bootstrap3', {
    groupRow: group_row,
    tableHeading: tableHeading
}).use(VueMonacoEditorPlugin, {
    paths: {
        // The recommended CDN config
        vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.43.0/min/vs'
    }
}).use(ServerTable).use(VueFormWizard).use(VNetworkGraph).use(head).directive('maska', vMaska);

app.mount('#app');
