<template>
    <!--  BEGIN SIDEBAR  -->
    <div class='sidebar-wrapper sidebar-theme'>
        <nav ref='menu' id='sidebar'>
            <div class='shadow-bottom'></div>

            <perfect-scrollbar class='list-unstyled menu-categories' tag='ul'
                               :options='{ wheelSpeed: 0.5, swipeEasing: !0, minScrollbarLength: 40, maxScrollbarLength: 300, suppressScrollX: true }'>

                <li class='menu'>
                    <router-link to='/home' class='dropdown-toggle' @click='toggleMobileMenu'>
                        <div class=''>
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'>
                                <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                <path
                                    d='M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z' />
                            </svg>
                            <span>{{ $t('home') }}</span>
                        </div>
                    </router-link>
                </li>

                <li class='menu' v-if='overallProgress !== 100'>
                    <router-link to='/journey' class='dropdown-toggle' @click='toggleMobileMenu'>
                        <div class='bg-primary rounded-2 p-3 '>
                            <span class='text-white'>
                                <svg xmlns='http://www.w3.org/2000/svg' height='48' viewBox='0 -960 960 960' width='48'
                                     fill='white'>
                                    <path
                                        d='M766-99q-50 0-86-30t-44-70H443q-69.062 0-113.531-44.664Q285-288.327 285-357.664 285-426 329.469-471T443-516h77q38.112 0 63.056-24.712Q608-565.425 608-603.212 608-641 583.017-666q-24.982-25-63.149-25H326q-12 40-46.857 70-34.856 30-85.476 30-55.5 0-95.084-39.441Q59-669.882 59-725.941T98.458-822q39.459-40 95.542-40 50.49 0 85.245 30.5t47.169 69.5H520q69.175 0 114.088 44.876Q679-672.248 679-603.124T634.088-489.5Q589.175-445 520-445h-77q-38.763 0-62.881 24.762Q356-395.476 356-357.619 356-318 380.119-294q24.118 24 62.394 24H636q10.297-38.812 45.005-69.406Q715.713-370 766.333-370q55.917 0 95.792 39.647t39.875 96Q902-178 862.25-138.5 822.5-99 766-99ZM193.702-671Q217-671 233.5-686.702q16.5-15.702 16.5-39Q250-749 233.711-765.5q-16.29-16.5-39.588-16.5-23.298 0-39.211 16.289Q139-749.421 139-726.123q0 23.298 15.702 39.211 15.702 15.912 39 15.912Z' /></svg>
                                
                                {{ $t('My Journey') }}</span>

                            <div class='progress progress-sm mt-3'>
                                <div class='progress-bar bg-success' role='progressbar'
                                     :aria-valuenow='overallProgress'
                                     aria-valuemin='0' aria-valuemax='100'
                                     :style='{ width: overallProgress + "%" }'></div>
                            </div>
                            <div class='percentage'>{{ overallProgress ? overallProgress.toFixed(0) : '0.00' }}%
                                completed
                            </div>
                        </div>
                    </router-link>
                </li>

                <li class='menu'>
                    <a class='dropdown-toggle' data-bs-toggle='collapse' data-bs-target='#assets' aria-controls='assets'
                       aria-expanded='false'>
                        <div class=''>
                            <font-awesome-icon icon='laptop' />
                            <span>{{ $t('assets') }}</span>
                        </div>
                        <div>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                class='feather feather-chevron-right'
                            >
                                <polyline points='9 18 15 12 9 6'></polyline>
                            </svg>
                        </div>
                    </a>

                    <ul id='assets' class='collapse submenu list-unstyled' data-bs-parent='#sidebar'>
                        <li>
                            <router-link to='/devices' @click='toggleMobileMenu'>Devices</router-link>
                        </li>
                        <li>
                            <router-link to='/networks' @click='toggleMobileMenu'>Networks</router-link>
                        </li>
                        <li>
                            <router-link to='/physical_storages' @click='toggleMobileMenu'>Physical Storages
                            </router-link>
                        </li>
                        <li>
                            <router-link to='/software' @click='toggleMobileMenu'>Software</router-link>
                        </li>
                        <li>
                            <router-link to='/storages' @click='toggleMobileMenu'>Storages</router-link>
                        </li>
                        <li>
                            <router-link to='/apps' @click='toggleMobileMenu'>User Apps</router-link>
                        </li>
                        <li>
                            <router-link to='/virtual_machines' @click='toggleMobileMenu'>Virtual Machines</router-link>
                        </li>
                    </ul>
                </li>

                <li class='menu'>
                    <a class='dropdown-toggle' data-bs-toggle='collapse' data-bs-target='#compliance'
                       aria-controls='components'
                       aria-expanded='false'>
                        <div class=''>
                            <font-awesome-icon icon='file-shield' />
                            <span>{{ $t('compliance') }}</span>
                        </div>
                        <div>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                class='feather feather-chevron-right'
                            >
                                <polyline points='9 18 15 12 9 6'></polyline>
                            </svg>
                        </div>
                    </a>

                    <ul id='compliance' class='collapse submenu list-unstyled' data-bs-parent='#sidebar'>
                        <li>
                            <router-link to='/compliance' @click='toggleMobileMenu'>Overview
                            </router-link>
                        </li>
                        <li>
                            <router-link to='/journey' @click='toggleMobileMenu'>My Journey</router-link>
                        </li>
                    </ul>
                </li>

                <li class='menu'>
                    <router-link to='/expirations' class='dropdown-toggle' @click='toggleMobileMenu'>
                        <div class=''>
                            <font-awesome-icon icon='calendar-alt' />
                            <span>{{ $t('expirations') }}</span>
                        </div>
                    </router-link>
                </li>

                
                <li class='menu'>
                    <a class='dropdown-toggle' data-bs-toggle='collapse' data-bs-target='#files' aria-controls='files'
                       aria-expanded='false'>
                        <div class=''>
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'>
                                <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                <path
                                    d='M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128z' />
                            </svg>
                            <span>{{ $t('files') }}</span>
                        </div>
                        <div>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                class='feather feather-chevron-right'
                            >
                                <polyline points='9 18 15 12 9 6'></polyline>
                            </svg>
                        </div>
                    </a>

                    <ul id='files' class='collapse submenu list-unstyled' data-bs-parent='#sidebar'>
                        <li>
                            <router-link to='/configurations' @click='toggleMobileMenu'>Configurations</router-link>
                        </li>
                        <li>
                            <router-link to='/documents' @click='toggleMobileMenu'>Documents</router-link>
                        </li>
                        <li>
                            <router-link to='/faq' @click='toggleMobileMenu'>FAQ</router-link>
                        </li>
                        <li>
                            <router-link to='/kb' @click='toggleMobileMenu'>{{ $t('knowledge base') }}</router-link>
                        </li>
                    </ul>
                </li>


                <li class='menu'>
                    <a class='dropdown-toggle' data-bs-toggle='collapse' data-bs-target='#components'
                       aria-controls='components'
                       aria-expanded='false'>
                        <div class=''>
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'>
                                <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                <path
                                    d='M14 2.2C22.5-1.7 32.5-.3 39.6 5.8L80 40.4 120.4 5.8c9-7.7 22.3-7.7 31.2 0L192 40.4 232.4 5.8c9-7.7 22.2-7.7 31.2 0L304 40.4 344.4 5.8c7.1-6.1 17.1-7.5 25.6-3.6s14 12.4 14 21.8V488c0 9.4-5.5 17.9-14 21.8s-18.5 2.5-25.6-3.6L304 471.6l-40.4 34.6c-9 7.7-22.2 7.7-31.2 0L192 471.6l-40.4 34.6c-9 7.7-22.3 7.7-31.2 0L80 471.6 39.6 506.2c-7.1 6.1-17.1 7.5-25.6 3.6S0 497.4 0 488V24C0 14.6 5.5 6.1 14 2.2zM96 144c-8.8 0-16 7.2-16 16s7.2 16 16 16H288c8.8 0 16-7.2 16-16s-7.2-16-16-16H96zM80 352c0 8.8 7.2 16 16 16H288c8.8 0 16-7.2 16-16s-7.2-16-16-16H96c-8.8 0-16 7.2-16 16zM96 240c-8.8 0-16 7.2-16 16s7.2 16 16 16H288c8.8 0 16-7.2 16-16s-7.2-16-16-16H96z' />
                            </svg>
                            <span>{{ $t('finances') }}</span>
                        </div>
                        <div>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                class='feather feather-chevron-right'
                            >
                                <polyline points='9 18 15 12 9 6'></polyline>
                            </svg>
                        </div>
                    </a>

                    <ul id='components' class='collapse submenu list-unstyled' data-bs-parent='#sidebar'>
                        <li>
                            <router-link to='/budgets' @click='toggleMobileMenu'>Budgets
                            </router-link>
                        </li>
                        <li>
                            <router-link to='/expenses' @click='toggleMobileMenu'>Expenses
                            </router-link>
                        </li>
                        <li>
                            <router-link to='/licenses' @click='toggleMobileMenu'>Licenses
                            </router-link>
                        </li>
                        <li>
                            <router-link to='/services' @click='toggleMobileMenu'>Services</router-link>
                        </li>
                        <li>
                            <router-link to='/subscriptions' @click='toggleMobileMenu'>Subscriptions
                            </router-link>
                        </li>
                        <li>
                            <router-link to='/projects' @click='toggleMobileMenu'>Projects</router-link>
                        </li>
                        <li>
                            <router-link to='/compensations' @click='toggleMobileMenu'>Compensations</router-link>
                        </li>
                    </ul>
                </li>

                <li class='menu'>
                    <router-link to='/my_tasks' class='dropdown-toggle' @click='toggleMobileMenu'>
                        <div class=''>
                            <font-awesome-icon icon='tasks' />
                            <span>{{ $t('my tasks') }}</span>
                        </div>
                    </router-link>
                </li>


                <li class='menu'>
                    <a class='dropdown-toggle' data-bs-toggle='collapse' data-bs-target='#people' aria-controls='people'
                       aria-expanded='false'>
                        <div class=''>
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
                                <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                <path
                                    d='M96 0C60.7 0 32 28.7 32 64V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H96zM208 288h64c44.2 0 80 35.8 80 80c0 8.8-7.2 16-16 16H144c-8.8 0-16-7.2-16-16c0-44.2 35.8-80 80-80zm96-96c0 35.3-28.7 64-64 64s-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64zM512 80c0-8.8-7.2-16-16-16s-16 7.2-16 16v64c0 8.8 7.2 16 16 16s16-7.2 16-16V80zM496 192c-8.8 0-16 7.2-16 16v64c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm16 144c0-8.8-7.2-16-16-16s-16 7.2-16 16v64c0 8.8 7.2 16 16 16s16-7.2 16-16V336z' />
                            </svg>
                            <span>{{ $t('people') }}</span>
                        </div>
                        <div>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                class='feather feather-chevron-right'
                            >
                                <polyline points='9 18 15 12 9 6'></polyline>
                            </svg>
                        </div>
                    </a>

                    <ul id='people' class='collapse submenu list-unstyled' data-bs-parent='#sidebar'>
                        <li>
                            <router-link to='/contacts' @click='toggleMobileMenu'>Contacts</router-link>
                        </li>
                        <li>
                            <router-link to='/employees' @click='toggleMobileMenu'>Employees</router-link>
                        </li>
                        <li>
                            <router-link to='/users' @click='toggleMobileMenu'>Users</router-link>
                        </li>
                    </ul>
                </li>

                <li class='menu'>
                    <router-link to='/procedures' class='dropdown-toggle' @click='toggleMobileMenu'>
                        <div class=''>
                            <font-awesome-icon icon='clipboard-list' />
                            <span>{{ $t('procedures') }}</span>
                        </div>
                    </router-link>
                </li>

                <li class='menu'>
                    <router-link to='/reports' class='dropdown-toggle' @click='toggleMobileMenu'>
                        <div class=''>
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'>
                                <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                <path
                                    d='M365.3 93.38l-74.63-74.64C278.6 6.742 262.3 0 245.4 0L64-.0001c-35.35 0-64 28.65-64 64l.0065 384c0 35.34 28.65 64 64 64H320c35.2 0 64-28.8 64-64V138.6C384 121.7 377.3 105.4 365.3 93.38zM336 448c0 8.836-7.164 16-16 16H64.02c-8.838 0-16-7.164-16-16L48 64.13c0-8.836 7.164-16 16-16h160L224 128c0 17.67 14.33 32 32 32h79.1V448zM96 280C96 293.3 106.8 304 120 304h144C277.3 304 288 293.3 288 280S277.3 256 264 256h-144C106.8 256 96 266.8 96 280zM264 352h-144C106.8 352 96 362.8 96 376s10.75 24 24 24h144c13.25 0 24-10.75 24-24S277.3 352 264 352z' />
                            </svg>
                            <span>{{ $t('reports') }}</span>
                        </div>
                    </router-link>
                </li>


                <li class='menu'>
                    <a class='dropdown-toggle' data-bs-toggle='collapse' data-bs-target='#security'
                       aria-controls='security'
                       aria-expanded='false'>
                        <div class=''>
                            <svg xmlns='http://www.w3.org/2000/svg' height='48' viewBox='0 -960 960 960' width='48'>
                                <path
                                    d='M480.085-58q-148.98-37.81-245.533-171.965Q138-364.12 138-521.767V-774.02L480-902l343 128.013v251.46q0 158.317-96.967 292.517Q629.065-95.81 480.085-58Zm-.348-93Q580-190 647.961-278.047 715.922-366.094 728-480.116H480V-804l-251 93.562v188.891q0 11.785 1 20.938 1 9.154 1.958 20.609h247.779v329Z' />
                            </svg>
                            <span>{{ $t('security') }}</span>
                        </div>
                        <div>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                class='feather feather-chevron-right'
                            >
                                <polyline points='9 18 15 12 9 6'></polyline>
                            </svg>
                        </div>
                    </a>

                    <ul id='security' class='collapse submenu list-unstyled' data-bs-parent='#sidebar'>
                        <li>
                            <router-link to='/hardening_templates' @click='toggleMobileMenu'>Hardening
                            </router-link>
                        </li>
                        <li>
                            <router-link to='/incident_responses' @click='toggleMobileMenu'>Incident Responses
                            </router-link>
                        </li>
                        <li>
                            <router-link to='/issue_management' @click='toggleMobileMenu'>Issue Management</router-link>
                        </li>
                        <li>
                            <router-link to='/keys' @click='toggleMobileMenu'>Key Manager</router-link>
                        </li>
                        <li>
                            <router-link to='/security_groups' @click='toggleMobileMenu'>Security Groups</router-link>
                        </li>
                    </ul>
                </li>

                <li class='menu'>
                    <a class='dropdown-toggle' data-bs-toggle='collapse' data-bs-target='#organization'
                       aria-controls='organization'
                       aria-expanded='false'>
                        <div class=''>
                            <font-awesome-icon icon='gear' />
                            <span>{{ $t('system') }}</span>
                        </div>
                        <div>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                class='feather feather-chevron-right'
                            >
                                <polyline points='9 18 15 12 9 6'></polyline>
                            </svg>
                        </div>
                    </a>

                    <ul id='organization' class='collapse submenu list-unstyled' data-bs-parent='#sidebar'>
                        <li>
                            <router-link
                                :to="{ name: 'organization_settings_view'}">
                                Settings
                            </router-link>
                        </li>
                        <li>
                            <router-link to='/locations' @click='toggleMobileMenu'>Locations</router-link>
                        </li>
                        <li>
                            <router-link to='/lookups' @click='toggleMobileMenu'>{{ $t('lookups') }}
                            </router-link>
                        </li>
                        <li>
                            <router-link to='/logs' @click='toggleMobileMenu'>{{ $t('logs') }}</router-link>
                        </li>
                        <li>
                            <router-link to='/organizations' @click='toggleMobileMenu'>{{ $t('organizations') }}
                            </router-link>
                        </li>
                        <li>
                            <router-link to='/security' @click='toggleMobileMenu'>{{ $t('security') }}
                            </router-link>
                        </li>
                    </ul>
                </li>


                <li class='menu'>
                    <a class='dropdown-toggle' data-bs-toggle='collapse' data-bs-target='#tools' aria-controls='tools'
                       aria-expanded='false'>
                        <div class=''>
                            <font-awesome-icon icon='screwdriver-wrench' />
                            <span>{{ $t('tools') }}</span>
                        </div>
                        <div>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                class='feather feather-chevron-right'
                            >
                                <polyline points='9 18 15 12 9 6'></polyline>
                            </svg>
                        </div>
                    </a>

                    <ul id='tools' class='collapse submenu list-unstyled' data-bs-parent='#sidebar'>
                        <li>
                            <router-link to='/diagrams' @click='toggleMobileMenu'>Diagrams</router-link>
                        </li>
                        <li>
                            <router-link to='/photos' @click='toggleMobileMenu'>Photo Manager</router-link>
                        </li>
                        <li>
                            <router-link to='/port_checker' @click='toggleMobileMenu'>Port Checker</router-link>
                        </li>
                        <li>
                            <router-link to='/racks' @click='toggleMobileMenu'>Rack Manager</router-link>
                        </li>
                        <li>
                            <router-link to='/ssl_inspector' @click='toggleMobileMenu'>SSL Inspector</router-link>
                        </li>
                        <li>
                            <router-link to='/email_guard_scanner' @click='toggleMobileMenu'>Email Guard Scanner
                            </router-link>
                        </li>
                    </ul>
                </li>


                <li class='menu'>
                    <a class='dropdown-toggle' data-bs-toggle='collapse' data-bs-target='#third_party'
                       aria-controls='third-party'
                       aria-expanded='false'>
                        <div class=''>
                            <svg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 640 512'>
                                <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                <path
                                    d='M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c1.8 0 3.5-.2 5.3-.5c-76.3-55.1-99.8-141-103.1-200.2c-16.1-4.8-33.1-7.3-50.7-7.3H178.3zm308.8-78.3l-120 48C358 277.4 352 286.2 352 296c0 63.3 25.9 168.8 134.8 214.2c5.9 2.5 12.6 2.5 18.5 0C614.1 464.8 640 359.3 640 296c0-9.8-6-18.6-15.1-22.3l-120-48c-5.7-2.3-12.1-2.3-17.8 0zM591.4 312c-3.9 50.7-27.2 116.7-95.4 149.7V273.8L591.4 312z' />
                            </svg>
                            <span>{{ $t('third party') }}</span>
                        </div>
                        <div>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                class='feather feather-chevron-right'
                            >
                                <polyline points='9 18 15 12 9 6'></polyline>
                            </svg>
                        </div>
                    </a>

                    <ul id='third_party' class='collapse submenu list-unstyled' data-bs-parent='#sidebar'>
                        <li>
                            <router-link to='/clients/' @click='toggleMobileMenu'>Clients</router-link>
                        </li>
                        <li>
                            <router-link to='/connectors' @click='toggleMobileMenu'>
                                {{ $t('connectors') }}
                            </router-link>
                        </li>
                        <li>
                            <router-link to='/vendors/' @click='toggleMobileMenu'>Vendors</router-link>
                        </li>
                    </ul>
                </li>

                <li class='menu'>
                    <a class='dropdown-toggle' data-bs-toggle='collapse' data-bs-target='#domains'
                       aria-controls='domains'
                       aria-expanded='false'>
                        <div class=''>
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
                                <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                <path
                                    d='M57.7 193l9.4 16.4c8.3 14.5 21.9 25.2 38 29.8L163 255.7c17.2 4.9 29 20.6 29 38.5v39.9c0 11 6.2 21 16 25.9s16 14.9 16 25.9v39c0 15.6 14.9 26.9 29.9 22.6c16.1-4.6 28.6-17.5 32.7-33.8l2.8-11.2c4.2-16.9 15.2-31.4 30.3-40l8.1-4.6c15-8.5 24.2-24.5 24.2-41.7v-8.3c0-12.7-5.1-24.9-14.1-33.9l-3.9-3.9c-9-9-21.2-14.1-33.9-14.1H257c-11.1 0-22.1-2.9-31.8-8.4l-34.5-19.7c-4.3-2.5-7.6-6.5-9.2-11.2c-3.2-9.6 1.1-20 10.2-24.5l5.9-3c6.6-3.3 14.3-3.9 21.3-1.5l23.2 7.7c8.2 2.7 17.2-.4 21.9-7.5c4.7-7 4.2-16.3-1.2-22.8l-13.6-16.3c-10-12-9.9-29.5 .3-41.3l15.7-18.3c8.8-10.3 10.2-25 3.5-36.7l-2.4-4.2c-3.5-.2-6.9-.3-10.4-.3C163.1 48 84.4 108.9 57.7 193zM464 256c0-36.8-9.6-71.4-26.4-101.5L412 164.8c-15.7 6.3-23.8 23.8-18.5 39.8l16.9 50.7c3.5 10.4 12 18.3 22.6 20.9l29.1 7.3c1.2-9 1.8-18.2 1.8-27.5zm48 0c0 141.4-114.6 256-256 256S0 397.4 0 256S114.6 0 256 0S512 114.6 512 256z' />
                            </svg>
                            <span>{{ $t('web assets') }}</span>
                        </div>
                        <div>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                class='feather feather-chevron-right'
                            >
                                <polyline points='9 18 15 12 9 6'></polyline>
                            </svg>
                        </div>
                    </a>


                    <ul id='domains' class='collapse submenu list-unstyled' data-bs-parent='#sidebar'>
                        <li>
                            <router-link to='/bookmarks' @click='toggleMobileMenu'>Bookmarks</router-link>
                        </li>
                        <li>
                            <router-link to='/certificates' @click='toggleMobileMenu'>Certificates</router-link>
                        </li>
                        <li class='mb-4'>
                            <router-link to='/domains' @click='toggleMobileMenu'>Domains</router-link>
                        </li>
                    </ul>
                </li>


            </perfect-scrollbar>
        </nav>
    </div>
    <!--  END SIDEBAR  -->
</template>

<script setup>
    import { computed, defineProps, onMounted, ref } from 'vue';
    import { useStore } from 'vuex';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    const store = useStore();

    const props = defineProps({
        steps: Object
    });

    onMounted(() => {
        const selector = document.querySelector('#sidebar a[href="' + window.location.pathname + '"]');
        if (selector) {
            const ul = selector.closest('ul.collapse');
            if (ul) {
                let ele = ul.closest('li.menu').querySelectorAll('.dropdown-toggle');
                if (ele) {
                    ele = ele[0];
                    setTimeout(() => {
                        ele.click();
                    });
                }
            } else {
                selector.click();
            }
        }
    });

    const toggleMobileMenu = () => {
        if (window.innerWidth < 991) {
            store.commit('toggleSideBar', !store.state.is_show_sidebar);
        }
    };

    const onboardingData = computed(() => store.state.auth.token.user.onboarding);

    const totalTasks = computed(() => {
        let count = 0;
        let show_status = onboardingData.value.show_status;
        let counted_tasks = [];
        for (const category in onboardingData.value) {
            if (category !== 'skipped' && category !== 'status' && category !== 'show_status') {
                for (const task in onboardingData.value[category]) {
                    if (task !== 'accordion' && show_status?.[task]) {
                        counted_tasks.push(task);
                        count++;
                    }
                }
            }
        }
        return count;
    });

    const completedTasks = computed(() => {
        let count = 0;
        let show_status = onboardingData.value.show_status;
        for (const category in onboardingData.value) {
            if (category !== 'skipped' && category !== 'status' && category !== 'show_status') {
                for (const task in onboardingData.value[category]) {
                    if (onboardingData.value[category][task]) {
                        if (task !== 'accordion' && show_status?.[task]) {
                            count++;
                        }
                    }
                }
            }
        }
        return count;
    });

    const overallProgress = computed(() => {
        if (totalTasks.value === 0) return 0;
        return (completedTasks.value / totalTasks.value) * 100;
    });
</script>
<style>

    .percentage {
        margin-top: -5px;
    }
</style>
