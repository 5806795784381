import { createRouter, createWebHistory } from 'vue-router';

import store from '../store';
import TokenService from '../services/token.service';


const before = (to, from, next) => {
    try {
        const token = TokenService.getToken();
        if (token == null) {

            // set redirect to path after login
            let redirect = {};
            if (to.name !== 'home') {
                redirect = { redirect: to.fullPath };
            }

            // redirect to login page
            return next({ name: 'login', query: redirect });
        }

        if (token.user.has_missing_payment || token.user.is_active === false) {
            if (to.name !== 'account' && to.name !== 'account_delete_confirm') {
                return next({ name: 'account' });
            }

        }


    } catch (e) {
    }
    next();
};

const routes = [
    // Dashboard Home
    {
        path: '/',
        name: '',
        component: () => import(/* webpackChunkName: "root" */ '../views/dashboard/view.vue')
    },
    {
        path: '/home',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '../views/dashboard/view.vue')
    },
    {
        path: '/journey',
        name: 'journey',
        component: () => import(/* journey: "home" */ '../views/compliance/journey.vue')
    },
    {
        path: '/upgrade',
        name: 'upgrade',
        component: () => import(/* webpackChunkName: "upgrade" */ '@/views/system/account/upgrade.vue')
    },

    // Assets
    {
        path: '/devices',
        name: 'devices',
        component: () => import(/* webpackChunkName: "devices" */ '../views/assets/devices/grid-table-view.vue')
    },
    {
        path: '/devices/:id',
        name: 'device_view',
        component: () => import(/* webpackChunkName: "device_view" */ '../views/assets/devices/id-view.vue')
    },
    {
        path: '/virtual_machines',
        name: 'virtual_machines',
        component: () => import(/* webpackChunkName: "virtual_machines" */ '../views/assets/virtual_machines/grid-table-view.vue')
    },
    {
        path: '/virtual_machines/:id',
        name: 'virtual_machine_view',
        component: () => import(/* webpackChunkName: "virtual_machine_view" */ '../views/assets/virtual_machines/id-view.vue')
    },
    {
        path: '/networks',
        name: 'networks',
        component: () => import(/* webpackChunkName: "networks" */ '../views/assets/networks/grid-table-view.vue')
    },
    {
        path: '/networks/:id',
        name: 'network_view',
        component: () => import(/* webpackChunkName: "network_view" */ '../views/assets/networks/id-view.vue')
    },
    {
        path: '/networks/:id/subnets',
        name: 'subnets',
        component: () => import(/* webpackChunkName: "subnets" */ '../views/assets/networks/subnets/grid-table-view.vue')
    },
    {
        path: '/networks/:nid/subnets/:id',
        name: 'subnet_view',
        component: () => import(/* webpackChunkName: "subnet_view" */ '../views/assets/networks/subnets/id-view.vue')
    },
    {
        path: '/networks/:nid/subnets/:id/ip_addresses',
        name: 'ip_addresses',
        component: () => import(/* webpackChunkName: "ip_addresses" */ '@/views/assets/networks/subnets/ip_addresses/grid-table-view.vue')
    },
    {
        path: '/networks/:nid/subnets/:sid/ip_addresses/:id',
        name: 'ip_address_view',
        component: () => import(/* webpackChunkName: "ip_address_view" */ '@/views/assets/networks/subnets/ip_addresses/id-view.vue')
    },
    {
        path: '/physical_storages',
        name: 'physical_storages',
        component: () => import(/* webpackChunkName: "physical_storages" */ '../views/assets/physical_storages/grid-table-view.vue')
    },
    {
        path: '/physical_storages/:id',
        name: 'physical_storage_view',
        component: () => import(/* webpackChunkName: "physical_storage_view" */ '../views/assets/physical_storages/id-view.vue')
    },
    {
        path: '/racks/tool/:id',
        name: 'rack_tool_view',
        component: () => import(/* webpackChunkName: "rack_view" */ '@/views/tools/racks/tool-view.vue')
    },


    {
        path: '/storages',
        name: 'storages',
        component: () => import(/* webpackChunkName: "storages" */ '../views/assets/storages/grid-table-view.vue')
    },
    {
        path: '/storages/:id',
        name: 'storage_view',
        component: () => import(/* webpackChunkName: "storage_view" */ '../views/assets/storages/id-view.vue')
    },
    {
        path: '/storages/:id/data_sources',
        name: 'data_sources',
        component: () => import(/* webpackChunkName: "data_sources" */ '../views/assets/storages/data_sources/grid-table-view.vue')
    },
    {
        path: '/storages/:nid/data_sources/:id',
        name: 'data_source_view',
        component: () => import(/* webpackChunkName: "data_source_view" */ '../views/assets/storages/data_sources/id-view.vue')
    },
    {
        path: '/storages/:nid/data_sources/:id/data_source_privileges',
        name: 'data_source_privileges',
        component: () => import(/* webpackChunkName: "data_source_privileges" */ '@/views/assets/storages/data_sources/data_source_privileges/grid-table-view.vue')
    },
    {
        path: '/storages/:nid/data_sources/:sid/data_source_privileges/:id',
        name: 'data_source_privileges_view',
        component: () => import(/* webpackChunkName: "data_source_privileges_view" */ '@/views/assets/storages/data_sources/data_source_privileges/id-view.vue')
    },
    {
        path: '/storages/:nid/data_sources/:id/data_source_shares',
        name: 'data_source_shares',
        component: () => import(/* webpackChunkName: "data_source_shares" */ '@/views/assets/storages/data_sources/data_source_shares/grid-table-view.vue')
    },
    {
        path: '/storages/:nid/data_sources/:sid/data_source_shares/:id',
        name: 'data_source_share_view',
        component: () => import(/* webpackChunkName: "data_source_share_view" */ '@/views/assets/storages/data_sources/data_source_shares/id-view.vue')
    },

    // Files
    {
        path: '/documents',
        name: 'documents',
        component: () => import(/* webpackChunkName: "documents" */ '@/views/files/documents/grid-table-view.vue')
    },
    {
        path: '/documents/:id',
        name: 'document_view',
        component: () => import(/* webpackChunkName: "document_view" */ '@/views/files/documents/id-view.vue')
    },
    {
        path: '/public/documents/:token',
        name: 'document_public',
        component: () => import(/* webpackChunkName: "faq_public" */ '@/views/files/documents/public.vue'),
        meta: { layout: 'public' }
    },
    {
        path: '/configurations',
        name: 'configurations',
        component: () => import(/* webpackChunkName: "configurations" */ '@/views/files/configurations/grid-table-view.vue')
    },
    {
        path: '/public/configurations/:token',
        name: 'configuration_public',
        component: () => import(/* webpackChunkName: "faq_public" */ '@/views/files/configurations/public.vue'),
        meta: { layout: 'public' }
    },
    {
        path: '/configurations/:id',
        name: 'configuration_view',
        component: () => import(/* webpackChunkName: "configuration_view" */ '@/views/files/configurations/id-view.vue')
    },

    // web_assets
    {
        path: '/domains',
        name: 'domains',
        component: () => import(/* webpackChunkName: "domains" */ '../views/web_assets/domains/grid-table-view.vue')
    },
    {
        path: '/domains/:id',
        name: 'domain_view',
        component: () => import(/* webpackChunkName: "domain_view" */ '../views/web_assets/domains/id-view.vue')
    },
    {
        path: '/certificates',
        name: 'certificates',
        component: () => import(/* webpackChunkName: "certificates" */ '../views/web_assets/certificates/grid-table-view.vue')
    },
    {
        path: '/certificates/:id',
        name: 'certificate_view',
        component: () => import(/* webpackChunkName: "certificate_view" */ '../views/web_assets/certificates/id-view.vue')
    },
    {
        path: '/bookmarks',
        name: 'bookmarks',
        component: () => import(/* webpackChunkName: "bookmarks" */ '../views/web_assets/bookmarks/grid-table-view.vue')
    },
    {
        path: '/bookmarks/:id',
        name: 'bookmark_view',
        component: () => import(/* webpackChunkName: "bookmark_view" */ '../views/web_assets/bookmarks/id-view.vue')
    },

    // Security
    {
        path: '/keys',
        name: 'keys',
        component: () => import(/* webpackChunkName: "keys" */ '../views/security/keys/grid-table-view.vue')
    },
    {
        path: '/keys/:id',
        name: 'key_view',
        component: () => import(/* webpackChunkName: "key_view" */ '../views/security/keys/id-view.vue')
    },
    {
        path: '/issue_management',
        name: 'issue_management',
        component: () => import(/* webpackChunkName: "keys" */ '@/views/security/issue_management/grid-table-view.vue')
    },
    {
        path: '/issue_management/:id',
        name: 'issue_management_view',
        component: () => import(/* webpackChunkName: "key_view" */ '@/views/security/issue_management/id-view.vue')
    },
    {
        path: '/incident_responses',
        name: 'incident_response',
        component: () => import(/* webpackChunkName: "incident_response" */ '@/views/security/incident_responses/grid-table-view.vue')
    },
    {
        path: '/incident_responses/:id',
        name: 'incident_response_view',
        component: () => import(/* webpackChunkName: "incident_response_view" */ '@/views/security/incident_responses/id-view.vue')
    },
    {
        path: '/security_groups',
        name: 'security_groups',
        component: () => import(/* webpackChunkName: "security_groups" */ '../views/security/security_groups/grid-table-view.vue')
    },
    {
        path: '/security_groups/:id',
        name: 'security_group_view',
        component: () => import(/* webpackChunkName: "security_group_view" */ '../views/security/security_groups/id-view.vue')
    },
    {
        path: '/security_groups/:id/rules',
        name: 'security_group_rules',
        component: () => import(/* webpackChunkName: "security_group_rules" */ '../views/security/security_groups/security_group_rules/grid-table-view.vue')
    },
    {
        path: '/security_groups/:nid/rules/:id',
        name: 'security_group_rules_view',
        component: () => import(/* webpackChunkName: "security_group_rules_view" */ '../views/security/security_groups/security_group_rules/id-view.vue')
    },

    // Files
    {
        path: '/hardening_templates/',
        name: 'hardening_templates',
        component: () => import(/* webpackChunkName: "hardening_templates" */ '../views/security/hardening_templates/grid-table-view.vue')
    },
    {
        path: '/hardening_templates/:id',
        name: 'hardening_template_view',
        component: () => import(/* webpackChunkName: "hardening_template_view" */ '../views/security/hardening_templates/id-view.vue')
    },
    {
        path: '/public/hardening_templates/:token',
        name: 'hardening_template_public',
        component: () => import(/* webpackChunkName: "faq_public" */ '@/views/security/hardening_templates/public.vue'),
        meta: { layout: 'public' }
    },

    // Compliance
    {
        path: '/compliance/',
        name: 'compliance',
        component: () => import(/* webpackChunkName: "compliance" */ '../views/compliance/compliance.vue')
    },

    // Locations
    {
        path: '/locations',
        name: 'locations',
        component: () => import(/* webpackChunkName: "locations" */ '@/views/system/organizations/locations/grid-table-view.vue')
    },
    {
        path: '/locations/:id',
        name: 'location_view',
        component: () => import(/* webpackChunkName: "location_view" */ '@/views/system/organizations/locations/id-view.vue')
    },
    {
        path: '/locations/:id/rooms',
        name: 'rooms',
        component: () => import(/* webpackChunkName: "rooms" */ '@/views/system/organizations/locations/rooms/grid-table-view.vue')
    },
    {
        path: '/locations/:nid/rooms/:id',
        name: 'room_view',
        component: () => import(/* webpackChunkName: "room_view" */ '@/views/system/organizations/locations/rooms/id-view.vue')
    },

    // Expenses
    {
        path: '/budgets',
        name: 'budgets',
        component: () => import(/* webpackChunkName: "services" */ '@/views/finances/budgets/grid-table-view.vue')
    },
    {
        path: '/budgets/:id',
        name: 'budget_view',
        component: () => import(/* webpackChunkName: "budget_view" */ '@/views/finances/budgets/id-view.vue')
    },
    {
        path: '/budgets/summary/:id',
        name: 'budget_summary_view',
        component: () => import(/* webpackChunkName: "budget_view" */ '@/views/finances/budgets/summary-view.vue')
    },
    {
        path: '/services',
        name: 'services',
        component: () => import(/* webpackChunkName: "services" */ '@/views/finances/services/grid-table-view.vue')
    },
    {
        path: '/services/:id',
        name: 'service_view',
        component: () => import(/* webpackChunkName: "service_view" */ '@/views/finances/services/id-view.vue')
    },
    {
        path: '/services/:id/items',
        name: 'service_items',
        component: () => import(/* webpackChunkName: "service_items" */ '@/views/finances/services/service_items/grid-table-view.vue')
    },
    {
        path: '/services/:id/roles',
        name: 'service_role_associations',
        component: () => import(/* webpackChunkName: "service_role_associations" */ '@/views/finances/services/service_role_associations/grid-table-view.vue')
    },
    {
        path: '/services/:nid/roles/:id',
        name: 'service_role_association_view',
        component: () => import(/* webpackChunkName: "service_role_association_view" */ '@/views/finances/services/service_role_associations/id-view.vue')
    },
    {
        path: '/services/:nid/items/:id',
        name: 'service_item_view',
        component: () => import(/* webpackChunkName: "service_item_view" */ '@/views/finances/services/service_items/id-view.vue')
    },

    {
        path: '/licenses',
        name: 'licenses',
        component: () => import(/* webpackChunkName: "licenses" */ '@/views/finances/licenses/grid-table-view.vue')
    },
    {
        path: '/licenses/:id',
        name: 'license_view',
        component: () => import(/* webpackChunkName: "license_view" */ '@/views/finances/licenses/id-view.vue')
    },
    {
        path: '/licenses/:id/seats',
        name: 'license_seats',
        component: () => import(/* webpackChunkName: "license_seats" */ '@/views/finances/licenses/license_seats/grid-table-view.vue')
    },

    {
        path: '/licenses/:nid/seats/:id',
        name: 'license_seat_view',
        component: () => import(/* webpackChunkName: "license_seat_view" */ '@/views/finances/licenses/license_seats/id-view.vue')
    },
    {
        path: '/subscriptions',
        name: 'subscriptions',
        component: () => import(/* webpackChunkName: "subscriptions" */ '@/views/finances/subscriptions/grid-table-view.vue')
    },
    {
        path: '/subscriptions/:id/seats',
        name: 'subscription_seats',
        component: () => import(/* webpackChunkName: "subscription_seats" */ '@/views/finances/subscriptions/subscription_seats/grid-table-view.vue')
    },
    {
        path: '/software',
        name: 'software',
        component: () => import(/* webpackChunkName: "software" */ '@/views/assets/software/grid-table-view.vue')
    },
    {
        path: '/software/:id',
        name: 'software_view',
        component: () => import(/* webpackChunkName: "software_view" */ '@/views/assets/software/id-view.vue')
    },
    {
        path: '/software/:id/assets',
        name: 'software_assets',
        component: () => import(/* webpackChunkName: "software_assets" */ '@/views/assets/software/software_assets/grid-table-view.vue')
    },
    {
        path: '/software/:nid/assets/:id',
        name: 'software_asset_view',
        component: () => import(/* webpackChunkName: "software_asset_view" */ '@/views/assets/software/software_assets/id-view.vue')
    },
    {
        path: '/apps',
        name: 'apps',
        component: () => import(/* webpackChunkName: "apps" */ '@/views/assets/apps/grid-table-view.vue')
    },
    {
        path: '/apps/:id',
        name: 'apps_view',
        component: () => import(/* webpackChunkName: "apps_view" */ '@/views/assets/apps/id-view.vue')
    },
    {
        path: '/apps/:id/users',
        name: 'apps_users',
        component: () => import(/* webpackChunkName: "apps_users" */ '@/views/assets/apps/app_users/grid-table-view.vue')
    },
    {
        path: '/apps/:nid/users/:id',
        name: 'apps_device_view',
        component: () => import(/* webpackChunkName: "apps_device_view" */ '@/views/assets/apps/app_users/id-view.vue')
    },

    {
        path: '/projects',
        name: 'projects',
        component: () => import(/* webpackChunkName: "projects" */ '@/views/finances/projects/grid-table-view.vue')
    },
    {
        path: '/projects/:id',
        name: 'project_view',
        component: () => import(/* webpackChunkName: "project_view" */ '@/views/finances/projects/id-view.vue')
    },

    {
        path: '/projects/:id/items',
        name: 'project_items',
        component: () => import(/* webpackChunkName: "project_items" */ '@/views/finances/projects/project_items/grid-table-view.vue')
    },
    {
        path: '/projects/:nid/items/:id',
        name: 'project_item_view',
        component: () => import(/* webpackChunkName: "project_item_view" */ '@/views/finances/projects/project_items/id-view.vue')
    },

    {
        path: '/compensations',
        name: 'compensations',
        component: () => import(/* webpackChunkName: "projects" */ '@/views/finances/compensations/grid-table-view.vue')
    },
    {
        path: '/compensations/:id',
        name: 'compensation_view',
        component: () => import(/* webpackChunkName: "compensation_view" */ '@/views/finances/compensations/id-view.vue')
    },
    {
        path: '/subscriptions/:nid/seats/:id',
        name: 'subscription_seat_view',
        component: () => import(/* webpackChunkName: "subscription_seat_view" */ '@/views/finances/subscriptions/subscription_seats/id-view.vue')
    },
    {
        path: '/subscriptions/:id',
        name: 'subscription_view',
        component: () => import(/* webpackChunkName: "subscription_view" */ '@/views/finances/subscriptions/id-view.vue')
    },
    {
        path: '/expenses',
        name: 'expenses',
        component: () => import(/* webpackChunkName: "expenses" */ '@/views/finances/expenses/grid-table-view.vue')
    },
    {
        path: '/expenses/:id',
        name: 'expense_view',
        component: () => import(/* webpackChunkName: "expense_view" */ '@/views/finances/expenses/id-view.vue')
    },

    {
        path: '/expenses/:id/items',
        name: 'expense_items',
        component: () => import(/* webpackChunkName: "expense_items" */ '@/views/finances/expenses/expense_items/grid-table-view.vue')
    },
    {
        path: '/expenses/:nid/items/:id',
        name: 'expense_item_view',
        component: () => import(/* webpackChunkName: "expense_item_view" */ '@/views/finances/expenses/expense_items/id-view.vue')
    },

    // Reports
    {
        path: '/reports',
        name: 'reports',
        component: () => import(/* webpackChunkName: "reports" */ '../views/reports/grid-table-view.vue')
    },
    {
        path: '/reports/:id',
        name: 'report_view',
        component: () => import(/* webpackChunkName: "report_view" */ '../views/reports/id-view.vue')
    },
    {
        path: '/email_guard_report/:id',
        name: 'email_guard_report',
        component: () => import(/* webpackChunkName: "grade" */ '../views/reports/email_guard_report.vue')
    },
    {
        path: '/ssl_inspection/:id',
        name: 'ssl_inspection',
        component: () => import(/* webpackChunkName: "grade" */ '../views/reports/ssl_report.vue')
    },

    // Tools
    {
        path: '/diagrams',
        name: 'diagrams',
        component: () => import(/* webpackChunkName: "diagrams" */ '@/views/tools/diagrams/grid-table-view.vue')
    },
    {
        path: '/diagrams/:id',
        name: 'diagram_view',
        component: () => import(/* webpackChunkName: "diagram_view" */ '@/views/tools/diagrams/id-view.vue')
    },
    {
        path: '/diagrams/tool/:id',
        name: 'diagram_tool_view',
        component: () => import(/* webpackChunkName: "rack_view" */ '@/views/tools/diagrams/tool-view.vue')
    },
    {
        path: '/port_checker',
        name: 'port_checker',
        component: () => import(/* webpackChunkName: "diagrams" */ '@/views/tools/port_checker/view.vue')
    },
    {
        path: '/ssl_inspector',
        name: 'ssl_inspector',
        component: () => import(/* webpackChunkName: "diagrams" */ '@/views/tools/ssl_inspector/view.vue')
    },
    {
        path: '/email_guard_scanner',
        name: 'email_guard_scanner',
        component: () => import(/* webpackChunkName: "diagrams" */ '@/views/tools/email_guard_scanner/view.vue')
    },

    {
        path: '/racks',
        name: 'racks',
        component: () => import(/* webpackChunkName: "racks" */ '@/views/tools/racks/grid-table-view.vue')
    },
    {
        path: '/racks/:id',
        name: 'rack_view',
        component: () => import(/* webpackChunkName: "rack_view" */ '@/views/tools/racks/id-view.vue')
    },


    {
        path: '/photos',
        name: 'photos',
        component: () => import(/* webpackChunkName: "diagrams" */ '@/views/tools/photos/grid-table-view.vue')
    },
    {
        path: '/photos/:id',
        name: 'photo_view',
        component: () => import(/* webpackChunkName: "photo_view" */ '@/views/tools/photos/id-view.vue')
    },


    // Public Pages
    {
        path: '/pages/contact-us',
        name: 'contact-us',
        component: () => import(/* webpackChunkName: "pages-contact-us" */ '../views/pages/contact_us.vue')
    },
    {
        path: '/pages/faq',
        name: 'faq-public',
        component: () => import(/* webpackChunkName: "pages-faq" */ '../views/pages/faq.vue')
    },
    {
        path: '/pages/privacy-policy',
        name: 'privacy-policy',
        component: () => import(/* webpackChunkName: "pages-privacy-policy" */ '../views/pages/privacy_policy.vue')
    },
    {
        path: '/pages/error403',
        name: 'error403',
        component: () => import(/* webpackChunkName: "pages-error403" */ '../views/pages/error403.vue'),
        meta: { layout: 'auth' }
    },
    {
        path: '/pages/error404',
        name: 'error404',
        component: () => import(/* webpackChunkName: "pages-error404" */ '../views/pages/error404.vue'),
        meta: { layout: 'auth' }
    },
    {
        path: '/pages/error500',
        name: 'error500',
        component: () => import(/* webpackChunkName: "pages-error500" */ '../views/pages/error500.vue'),
        meta: { layout: 'auth' }
    },
    {
        path: '/pages/error503',
        name: 'error503',
        component: () => import(/* webpackChunkName: "pages-error503" */ '../views/pages/error503.vue'),
        meta: { layout: 'auth' }
    },
    {
        path: '/pages/maintenance',
        name: 'maintenance',
        component: () => import(/* webpackChunkName: "pages-maintenance" */ '../views/pages/maintenence.vue'),
        meta: { layout: 'auth' }
    },
    {
        path: '/thanks',
        name: 'thanks',
        component: () => import(/* webpackChunkName: "auth-login" */ '../views/thank_you'),
        meta: { layout: 'auth' }
    },

    // My Tasks
    {
        path: '/my_tasks',
        name: 'my_tasks',
        component: () => import(/* webpackChunkName: "my_tasks" */ '../views/my_tasks/grid-table-view.vue')
    },

    // Expirations
    {
        path: '/expirations',
        name: 'expirations',
        component: () => import(/* webpackChunkName: "my_tasks" */ '../views/expirations/grid-table-view.vue')
    },

    // People
    {
        path: '/users',
        name: 'users',
        component: () => import(/* webpackChunkName: "users" */ '@/views/people/users/grid-tavble-view.vue')
    },
    {
        path: '/users/:id',
        name: 'user_view',
        component: () => import(/* webpackChunkName: "user_view" */ '@/views/people/users/id-view.vue')
    },
    {
        path: '/employees',
        name: 'employees',
        component: () => import(/* webpackChunkName: "employees" */ '../views/people/employees/grid-table-view.vue')
    },
    {
        path: '/employees/:id',
        name: 'employee_view',
        component: () => import(/* webpackChunkName: "employee_view" */ '../views/people/employees/id-view.vue')
    },
    {
        path: '/employees/:id/trainings',
        name: 'trainings',
        component: () => import(/* webpackChunkName: "trainings" */ '../views/people/employees/trainings/grid-table-view.vue')
    },
    {
        path: '/employees/:nid/trainings/:id',
        name: 'training_view',
        component: () => import(/* webpackChunkName: "training_view" */ '../views/people/employees/trainings/id-view.vue')
    },

    {
        path: '/contacts',
        name: 'contacts',
        component: () => import(/* webpackChunkName: "contacts" */ '../views/people/contacts/grid-table-view.vue')
    },
    {
        path: '/contacts/:id',
        name: 'contact_view',
        component: () => import(/* webpackChunkName: "contact_view" */ '../views/people/contacts/id-view.vue')
    },


    // User Settings
    {
        path: '/auth/profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "profile" */ '../views/auth/profile.vue')
    },
    {
        path: '/auth/2fa',
        name: '2fa',
        component: () => import(/* webpackChunkName: "2fa" */ '../views/auth/2fa.vue')
    },
    {
        path: '/auth/2fa_recovery_keys',
        name: '2fa_recovery_keys',
        component: () => import(/* webpackChunkName: "2fa_recovery_keys" */ '../views/auth/2fa_recovery_keys.vue')
    },
    {
        path: '/auth/passkey_register',
        name: 'passkey_register',
        component: () => import(/* webpackChunkName: "2fa" */ '../views/auth/passkey_register.vue')
    },
    {
        path: '/auth/passkey',
        name: 'passkey',
        component: () => import(/* webpackChunkName: "2fa" */ '../views/auth/passkey.vue'),
        meta: { layout: 'auth' }
    },
    {
        path: '/auth/change_password',
        name: 'change_password',
        component: () => import(/* webpackChunkName: "change_password" */ '../views/auth/change_password.vue')
    },
    {
        path: '/auth/account_delete_confirm',
        name: 'account_delete_confirm',
        component: () => import(/* webpackChunkName: "account_delete_confirm" */ '../views/auth/account_delete_confirm.vue')
    },


    // Procedures
    {
        path: '/procedures',
        name: 'procedures',
        component: () => import(/* webpackChunkName: "procedures" */ '../views/procedures/procedures/grid-table-view.vue')
    },
    {
        path: '/public/procedures/:token',
        name: 'public_tasks',
        component: () => import(/* webpackChunkName: "public_tasks" */ '@/views/procedures/procedures/tasks/public.vue'),
        meta: { layout: 'auth' }
    },
    {
        path: '/procedures/:id',
        name: 'procedure_view',
        component: () => import(/* webpackChunkName: "procedure_view" */ '../views/procedures/procedures/id-view.vue')
    },
    {
        path: '/procedures/tasks/:id',
        name: 'tasks',
        component: () => import(/* webpackChunkName: "tasks" */ '@/views/procedures/procedures/tasks/view.vue')
    },

    // FAQ
    {
        path: '/faq',
        name: 'faq',
        component: () => import(/* webpackChunkName: "faq" */ '@/views/files/faq/grid-table-view.vue')
    },
    {
        path: '/public/faq/:token',
        name: 'faq_public',
        component: () => import(/* webpackChunkName: "faq_public" */ '@/views/files/faq/public.vue'),
        meta: { layout: 'public' }
    },
    {
        path: '/faq/:id',
        name: 'faq_view',
        component: () => import(/* webpackChunkName: "faq_view" */ '@/views/files/faq/id-view.vue')
    },

    // KB
    {
        path: '/knowledge_base',
        name: 'knowledge_base',
        component: () => import(/* webpackChunkName: "knowledge_base" */ '@/views/files/knowledge_bases/grid-table-view.vue')
    },
    {
        path: '/kb',
        name: 'kb',
        component: () => import(/* webpackChunkName: "kb" */ '@/views/files/knowledge_bases/grid-table-view.vue')
    },
    {
        path: '/public/kb/:token',
        name: 'kb_public',
        component: () => import(/* webpackChunkName: "kb_public" */ '@/views/files/knowledge_bases/public.vue'),
        meta: { layout: 'public' }
    },
    {
        path: '/kb/:id',
        name: 'kb_view',
        component: () => import(/* webpackChunkName: "kb_view" */ '@/views/files/knowledge_bases/id-view.vue')
    },
    {
        path: '/knowledge_base/:id',
        name: 'knowledge_base_view',
        component: () => import(/* webpackChunkName: "knowledge_base_view" */ '@/views/files/knowledge_bases/id-view.vue')
    },

    // Settings
    {
        path: '/security',
        name: 'organization_security_view',
        component: () => import(/* webpackChunkName: "organization_security_view" */ '@/views/system/security/view.vue')
    },

    {
        path: '/lookups/applications',
        name: 'applications',
        component: () => import(/* webpackChunkName: "applications" */ '@/views/system/organizations/lookups/applications/view.vue')
    },
    {
        path: '/organizations',
        name: 'organizations',
        component: () => import(/* webpackChunkName: "system" */ '@/views/system/organizations/grid-table-view.vue')
    },
    {
        path: '/logs',
        name: 'logs',
        component: () => import(/* webpackChunkName: "logs" */ '@/views/system/logs/grid-table-view.vue')
    },
    {
        path: '/backups',
        name: 'backups',
        component: () => import(/* webpackChunkName: "backups" */ '@/views/system/organizations/lookups/backups/grid-table-view.vue')
    },
    {
        path: '/stripe/invoices',
        name: 'invoices',
        component: () => import(/* webpackChunkName: "invoices" */ '../views/stripe/invoices.vue')
    },
    {
        path: '/settings',
        name: 'organization_settings_view',
        component: () => import(/* webpackChunkName: "organization_settings_view" */ '@/views/system/settings/view.vue')
    },
    {
        path: '/lookups',
        name: 'lookups',
        component: () => import(/* webpackChunkName: "organization" */ '@/views/system/organizations/lookups/view.vue')
    },
    {
        path: '/account',
        name: 'account',
        component: () => import(/* webpackChunkName: "account" */ '@/views/system/account/view.vue')
    },
    {
        path: '/account/cancel',
        name: 'cancel',
        component: () => import(/* webpackChunkName: "cancel" */ '@/views/system/account/cancel.vue')
    },
    {
        path: '/lookups/contact_types',
        name: 'contact_types',
        component: () => import(/* webpackChunkName: "contact_types" */ '@/views/system/organizations/lookups/contact_types/view.vue')
    },
    {
        path: '/lookups/device_categories',
        name: 'device_categories',
        component: () => import(/* webpackChunkName: "category" */ '@/views/system/organizations/lookups/device_categories/view.vue')
    },
    {
        path: '/lookups/device_types',
        name: 'device_types',
        component: () => import(/* webpackChunkName: "type" */ '@/views/system/organizations/lookups/device_types/view.vue')
    },
    {
        path: '/lookups/data_classifications',
        name: 'data_classifications',
        component: () => import(/* webpackChunkName: "type" */ '@/views/system/organizations/lookups/data_classifications/view.vue')
    },
    {
        path: '/lookups/asset_classifications',
        name: 'asset_classifications',
        component: () => import(/* webpackChunkName: "type" */ '@/views/system/organizations/lookups/asset_classifications/view.vue')
    },
    {
        path: '/lookups/security_zone_classifications',
        name: 'security_zone_classifications',
        component: () => import(/* webpackChunkName: "type" */ '@/views/system/organizations/lookups/security_zone_classifications/view.vue')
    },
    {
        path: '/lookups/device_brands',
        name: 'device_brands',
        component: () => import(/* webpackChunkName: "device_brands" */ '@/views/system/organizations/lookups/device_brands/view.vue')
    },
    {
        path: '/lookups/device_models',
        name: 'device_models',
        component: () => import(/* webpackChunkName: "models" */ '@/views/system/organizations/lookups/device_models/view.vue')
    },
    {
        path: '/lookups/device_statuses',
        name: 'device_statuses',
        component: () => import(/* webpackChunkName: "status" */ '@/views/system/organizations/lookups/device_statuses/view.vue')
    },
    {
        path: '/lookups/data_sensitivity_levels',
        name: 'data_sensitivity_levels',
        component: () => import(/* webpackChunkName: "data_sensitivity_levels" */ '@/views/system/organizations/lookups/data_sensitivity_levels/view.vue')
    },
    {
        path: '/lookups/data_critical_levels',
        name: 'data_critical_levels',
        component: () => import(/* webpackChunkName: "data_critical_levels" */ '@/views/system/organizations/lookups/data_critical_levels/view.vue')
    },
    {
        path: '/lookups/dns_filter_providers',
        name: 'dns_filter_providers',
        component: () => import(/* webpackChunkName: "dns_filter_providers" */ '@/views/system/organizations/lookups/dns_filter_providers/view.vue')
    },
    {
        path: '/lookups/endpoint_protection_platforms',
        name: 'endpoint_protection_platforms',
        component: () => import(/* webpackChunkName: "endpoint_protection_platforms" */ '@/views/system/organizations/lookups/endpoint_protection_platforms/view.vue')
    },
    {
        path: '/lookups/email_spam_providers',
        name: 'email_spam_providers',
        component: () => import(/* webpackChunkName: "email_spam_providers" */ '@/views/system/organizations/lookups/email_spam_providers/view.vue')
    },
    {
        path: '/lookups/configuration_categories',
        name: 'configuration_categories',
        component: () => import(/* webpackChunkName: "configuration_categories" */ '@/views/system/organizations/lookups/configuration_categories/view.vue')
    },
    {
        path: '/lookups/document_categories',
        name: 'document_categories',
        component: () => import(/* webpackChunkName: "document_categories" */ '@/views/system/organizations/lookups/document_categories/view.vue')
    },
    {
        path: '/lookups/hardening_template_categories',
        name: 'hardening_template_categories',
        component: () => import(/* webpackChunkName: "hardening_template_categories" */ '@/views/system/organizations/lookups/hardening_template_categories/view.vue')
    },
    {
        path: '/lookups/expense_categories',
        name: 'expense_categories',
        component: () => import(/* webpackChunkName: "expense_categories" */ '@/views/system/organizations/lookups/expense_categories/view.vue')
    },
    {
        path: '/lookups/faq_categories',
        name: 'faq_categories',
        component: () => import(/* webpackChunkName: "faq_categories" */ '@/views/system/organizations/lookups/faq_categories/view.vue')
    },
    {
        path: '/lookups/knowledge_base_categories',
        name: 'knowledge_base_categories',
        component: () => import(/* webpackChunkName: "faq_categories" */ '@/views/system/organizations/lookups/knowledge_base_categories/view.vue')
    },
    {
        path: '/lookups/expense_types',
        name: 'expense_types',
        component: () => import(/* webpackChunkName: "expense_types" */ '@/views/system/organizations/lookups/expense_types/view.vue')
    },
    {
        path: '/lookups/expense_statuses',
        name: 'expense_statuses',
        component: () => import(/* webpackChunkName: "expense_statuses" */ '@/views/system/organizations/lookups/expense_statuses/view.vue')
    },
    {
        path: '/lookups/license_types',
        name: 'license_types',
        component: () => import(/* webpackChunkName: "license_types" */ '@/views/system/organizations/lookups/license_types/view.vue')
    },
    {
        path: '/lookups/license_roles',
        name: 'license_roles',
        component: () => import(/* webpackChunkName: "license_roles" */ '@/views/system/organizations/lookups/license_roles/view.vue')
    },
    {
        path: '/lookups/license_sectors',
        name: 'license_sectors',
        component: () => import(/* webpackChunkName: "license_sectors" */ '@/views/system/organizations/lookups/license_sectors/view.vue')
    },
    {
        path: '/lookups/ip_address_statuses',
        name: 'ip_address_statuses',
        component: () => import(/* webpackChunkName: "ip_address_statuses" */ '@/views/system/organizations/lookups/ip_address_statuses/view.vue')
    },
    {
        path: '/lookups/network_connection_types',
        name: 'network_connection_types',
        component: () => import(/* webpackChunkName: "license_sectors" */ '@/views/system/organizations/lookups/network_connection_types/view.vue')
    },
    {
        path: '/lookups/mfa_providers',
        name: 'mfa_providers',
        component: () => import(/* webpackChunkName: "mfa_providers" */ '@/views/system/organizations/lookups/mfa_providers/view.vue')
    },
    {
        path: '/lookups/rmm_agents',
        name: 'rmm_agents',
        component: () => import(/* webpackChunkName: "rmm_agents" */ '@/views/system/organizations/lookups/rmm_agents/view.vue')
    }, {
        path: '/lookups/backup_types',
        name: 'backup_types',
        component: () => import(/* webpackChunkName: "backup_types" */ '@/views/system/organizations/lookups/backup_types/view.vue')
    }, {
        path: '/lookups/encryption_types',
        name: 'encryption_types',
        component: () => import(/* webpackChunkName: "encryption_types" */ '@/views/system/organizations/lookups/encryption_types/view.vue')
    },
    {
        path: '/lookups/privilege_types',
        name: 'privilege_types',
        component: () => import(/* webpackChunkName: "privilege_types" */ '@/views/system/organizations/lookups/privilege_types/view.vue')
    },
    {
        path: '/lookups/phone_providers',
        name: 'phone_providers',
        component: () => import(/* webpackChunkName: "phone_providers" */ '@/views/system/organizations/lookups/phone_providers/view.vue')
    },
    {
        path: '/lookups/folders/',
        name: 'folders',
        component: () => import(/* webpackChunkName: "folders" */ '@/views/system/organizations/lookups/folders/view.vue')
    },
    {
        path: '/lookups/employee_types',
        name: 'employee_types',
        component: () => import(/* webpackChunkName: "employee_types" */ '@/views/system/organizations/lookups/employee_types/view.vue')
    },
    {
        path: '/lookups/employee_work_arrangements',
        name: 'employee_work_arrangements',
        component: () => import(/* webpackChunkName: "employee_work_arrangements" */ '@/views/system/organizations/lookups/employee_work_arrangements/view.vue')
    },
    {
        path: '/lookups/environments',
        name: 'environments',
        component: () => import(/* webpackChunkName: "environments" */ '@/views/system/organizations/lookups/environments/view.vue')
    },
    {
        path: '/lookups/platforms',
        name: 'platforms',
        component: () => import(/* webpackChunkName: "platforms" */ '@/views/system/organizations/lookups/platforms/view.vue')
    },
    {
        path: '/lookups/procedure_statuses',
        name: 'procedure_statuses',
        component: () => import(/* webpackChunkName: "procedure_statuses" */ '@/views/system/organizations/lookups/procedure_statuses/view.vue')
    },
    {
        path: '/lookups/processor_families',
        name: 'processor_families',
        component: () => import(/* webpackChunkName: "processor_families" */ '@/views/system/organizations/lookups/processor_families/view.vue')
    },
    {
        path: '/lookups/project_statuses',
        name: 'project_statuses',
        component: () => import(/* webpackChunkName: "project_statuses" */ '@/views/system/organizations/lookups/project_statuses/view.vue')
    },
    {
        path: '/lookups/subscription_types',
        name: 'subscription_types',
        component: () => import(/* webpackChunkName: "subscription_types" */ '@/views/system/organizations/lookups/subscription_types/view.vue')
    },
    {
        path: '/lookups/subscription_roles',
        name: 'subscription_roles',
        component: () => import(/* webpackChunkName: "subscription_roles" */ '@/views/system/organizations/lookups/subscription_roles/view.vue')
    },
    {
        path: '/lookups/service_roles',
        name: 'service_roles',
        component: () => import(/* webpackChunkName: "service_roles" */ '@/views/system/organizations/lookups/service_roles/view.vue')
    },
    {
        path: '/lookups/subscription_sectors',
        name: 'subscription_sectors',
        component: () => import(/* webpackChunkName: "subscription_sectors" */ '@/views/system/organizations/lookups/subscription_sectors/view.vue')
    },
    {
        path: '/lookups/storage_types',
        name: 'storage_types',
        component: () => import(/* webpackChunkName: "storage_types" */ '@/views/system/organizations/lookups/storage_types/view.vue')
    },
    {
        path: '/lookups/training_providers',
        name: 'training_providers',
        component: () => import(/* webpackChunkName: "training_providers" */ '@/views/system/organizations/lookups/training_providers/view.vue')
    },
    {
        path: '/switch/:id',
        name: 'switch_organization',
        component: () => import(/* webpackChunkName: "switch_organization" */ '@/views/system/switch.vue')
    },
    {
        path: '/lookups/machine_functions',
        name: 'machine_functions',
        component: () => import(/* webpackChunkName: "machine_functions" */ '@/views/system/organizations/lookups/machine_functions/view.vue')
    },
    {
        path: '/lookups/groups',
        name: 'groups',
        component: () => import(/* webpackChunkName: "groups" */ '@/views/system/organizations/lookups/groups/view.vue')
    },
    {
        path: '/lookups/tags',
        name: 'tags',
        component: () => import(/* webpackChunkName: "tags" */ '@/views/system/organizations/lookups/tags/view.vue')
    },
    {
        path: '/lookups/ports',
        name: 'ports',
        component: () => import(/* webpackChunkName: "ports" */ '@/views/system/organizations/lookups/ports/view.vue')
    },
    {
        path: '/lookups/virtual_machine_availability_zones',
        name: 'virtual_machine_availability_zones',
        component: () => import(/* webpackChunkName: "virtual_machine_availability_zones" */ '@/views/system/organizations/lookups/virtual_machine_availability_zones/view.vue')
    },
    {
        path: '/lookups/virtual_machine_statuses',
        name: 'virtual_machine_statuses',
        component: () => import(/* webpackChunkName: "virtual_machine_statuses" */ '@/views/system/organizations/lookups/virtual_machine_statuses/view.vue')
    },
    {
        path: '/lookups/network_statuses',
        name: 'network_statuses',
        component: () => import(/* webpackChunkName: "network_statuses" */ '@/views/system/organizations/lookups/network_statuses/view.vue')
    },
    {
        path: '/lookups/virtual_machine_types',
        name: 'virtual_machine_types',
        component: () => import(/* webpackChunkName: "virtual_machine_types" */ '@/views/system/organizations/lookups/virtual_machine_types/view.vue')
    },
    {
        path: '/lookups/vrfs',
        name: 'vrfs',
        component: () => import(/* webpackChunkName: "vrfs" */ '@/views/system/organizations/lookups/vrfs/view.vue')
    },

    {
        path: '/lookups/incident_response_categories',
        name: 'incident_response_categories',
        component: () => import(/* webpackChunkName: "incident_response_categories" */ '@/views/system/organizations/lookups/incident_response_categories/view.vue')
    },
    {
        path: '/lookups/incident_response_statuses',
        name: 'incident_response_statuses',
        component: () => import(/* webpackChunkName: "incident_response_statuses" */ '@/views/system/organizations/lookups/incident_response_statuses/view.vue')
    },
    {
        path: '/lookups/impact_levels',
        name: 'impact_levels',
        component: () => import(/* webpackChunkName: "impact_levels" */ '@/views/system/organizations/lookups/impact_levels/view.vue')
    },
    {
        path: '/lookups/issue_management_statuses',
        name: 'issue_management_statuses',
        component: () => import(/* webpackChunkName: "issue_management_statuses" */ '@/views/system/organizations/lookups/issue_management_statuses/view.vue')
    },


    // Third Party
    {
        path: '/vendors',
        name: 'vendors',
        component: () => import(/* webpackChunkName: "vendors" */ '@/views/third_party/vendors/grid-table-view.vue')
    },
    {
        path: '/vendors/:id',
        name: 'vendor_view',
        component: () => import(/* webpackChunkName: "vendor_view" */ '@/views/third_party/vendors/id-view.vue')
    },
    {
        path: '/vendors/:id/lifecycles',
        name: 'lifecycles',
        component: () => import(/* webpackChunkName: "lifecycles" */ '../views/third_party/vendors/lifecycles/grid-table-view.vue')
    },
    {
        path: '/vendors/:nid/lifecycles/:id',
        name: 'lifecycle_view',
        component: () => import(/* webpackChunkName: "lifecycle_view" */ '../views/third_party/vendors/lifecycles/id-view.vue')
    },
    {
        path: '/clients',
        name: 'clients',
        component: () => import(/* webpackChunkName: "clients" */ '@/views/third_party/clients/grid-table-view.vue')
    },
    {
        path: '/clients/:id',
        name: 'client_view',
        component: () => import(/* webpackChunkName: "client_view" */ '@/views/third_party/clients/id-view.vue')
    },
    {
        path: '/connectors',
        name: 'connectors',
        component: () => import(/* webpackChunkName: "connectors" */ '@/views/third_party/connectors.vue')
    },
    {
        path: '/connectors/:type',
        name: 'connector_authorized',
        component: () => import(/* webpackChunkName: "connector_type" */ '@/views/third_party/connector_authorized.vue')
    },

    // Login and Registration
    {
        path: '/auth/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "auth-login" */ '../views/auth/login.vue'),
        meta: { layout: 'auth' }
    },
    {
        path: '/auth/code',
        name: 'code',
        component: () => import(/* webpackChunkName: "auth-code" */ '../views/auth/code.vue'),
        meta: { layout: 'auth' }
    },
    {
        path: '/auth/force_logout',
        name: 'force_logout'
    },
    {
        path: '/auth/logout',
        name: 'logout'
    },
    {
        path: '/auth/register',
        name: 'register',
        component: () => import(/* webpackChunkName: "auth-register" */ '../views/auth/register.vue'),
        meta: { layout: 'auth' }
    },
    {
        path: '/auth/password_recovery',
        name: 'password_recovery',
        component: () => import(/* webpackChunkName: "auth-password-recovery" */ '../views/auth/password_recovery.vue'),
        meta: { layout: 'auth' }
    },
    {
        path: '/auth/confirmation/:token',
        name: 'confirmation',
        component: () => import(/* webpackChunkName: "confirmation" */ '../views/auth/confirmation.vue'),
        meta: { layout: 'auth' }
    },
    {
        path: '/auth/invitation/:token',
        name: 'invitation',
        component: () => import(/* webpackChunkName: "invitation" */ '../views/auth/invitation.vue'),
        meta: { layout: 'auth' }
    },
    {
        path: '/auth/password_reset/:token',
        name: 'password_reset',
        component: () => import(/* webpackChunkName: "password_reset" */ '../views/auth/password_reset.vue'),
        meta: { layout: 'auth' }
    },

    // catch all redirect to home page
    { path: '/:pathMatch(.*)*', redirect: '/' }
];

const router = new createRouter({
    // mode: 'history',
    history: createWebHistory(),
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { left: 0, top: 0 };
        }
    }
});

router.beforeEach(async (to, from, next) => {

    if (to.meta && to.meta.layout && to.meta.layout === 'auth') {
        store.commit('setLayout', 'auth');
        next();
    } else if (to.meta && to.meta.layout && to.meta.layout === 'public') {
        store.commit('setLayout', 'public');
        next();
    } else if (to.name === 'force_logout') {
        store.commit('setLayout', 'app');

        // set redirect to path after login
        let redirect = { redirect: store.getters.redirectPath };
        await store.dispatch('auth/logout');

        // redirect to login page
        next({ name: 'login', query: redirect });
    } else if (to.name === 'logout') {
        await store.dispatch('auth/logout');
        next({ name: 'login' });
    } else {
        store.commit('setLayout', 'app');
        store.commit('setRedirectPath', to.fullPath);

        // const cachedObject = localStorage.getItem('cachedObject');
        // if (cachedObject) {
        //     store.commit('setCachedObject', JSON.parse(cachedObject));
        // }

        before(to, from, next);
    }
});

export default router;
